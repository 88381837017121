<script>
  import {
    isModalOpen,
    closeModal,
    iframeTitle,
    iframeSrc,
  } from "../../stores/modalStore.ts";

  let dialog; // HTMLDialogElement

  $: if (dialog && $isModalOpen) dialog.showModal();
  $: if (dialog && !$isModalOpen) dialog.close();
</script>

<!-- svelte-ignore a11y-click-events-have-key-events a11y-no-noninteractive-element-interactions -->
<dialog
  class="border-0 max-w-screen-lg w-full rounded-md open:backdrop:animate-fade backdrop:bg-black/50 open:animate-zoom"
  bind:this={dialog}
  on:close={() => isModalOpen.set(false)}
  on:click|self={() => closeModal()}
>
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div on:click|stopPropagation class="w-full aspect-video">
    <slot name="header" />
    <slot />
    {#if $isModalOpen}
      <div class="iframe-container">
        <iframe
          frameborder="0"
          allowfullscreen=""
          allow="autoplay; fullscreen"
          src={$iframeSrc}
          data-ready="true"
          title={$iframeTitle}
        ></iframe>
      </div>
    {/if}
  </div>
</dialog>

<style>
  .iframe-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
  }
  .iframe-container iframe {
    background: #000;
    box-shadow: 0 0 8px #0009;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
</style>
